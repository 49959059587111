import { useNavigate } from "react-router-dom";
import { useState } from "react";
export const GoToAssistants = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      className="fixed top-0 left-0  h-[10px] w-[300px]"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && (
        <div
          className="h-[40px]  w-[300px] leading-[40px] text-center bg-[#71c6b1] text-[#ffffff] cursor-pointer"
          onClick={() => {
            navigate("/assistants");
          }}
        >
          返回
        </div>
      )}
    </div>
  );
};
