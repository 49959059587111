import { types } from "mobx-state-tree";

export const ExtractSession = types
  .model("ExtractSession", {
    editList: types.array(types.frozen()),
    currentIntentionId: types.string,
    search: types.string,
    newIntention:types.array(types.string),
    intentionList:types.array(types.string),
    hiddenIntention: types.array(types.string),
    currenQuerytList:types.array(types.string),
  })
  .actions((self) => ({
    setEditList(list) {
      self.editList.replace(list);
    },
    setCurrentIntentionId(val) {
      self.currentIntentionId = val;
    },
    setSearch(val) {
      self.search = val;
    },
    setNewIntention(val) {
      self.newIntention = val;
    },
    setHiddenIntention(val) {
      self.hiddenIntention = val;
    },
    setIntentionList(val) {
      self.intentionList = val;
    },
    setCurrenQuerytList(val) {
      self.currenQuerytList = val;
    }
  }));
