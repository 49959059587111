const debugUrl = import.meta.env.PROD
  ? "http://100.64.0.7:3000"
  : "http://localhost:3000";

export const gotoDebug = (url: string) => {
  window.open(
    `${debugUrl}/api/redirect?endpoint=${encodeURIComponent(
      window.location.origin,
    )}&redirect=${encodeURIComponent(`${debugUrl}/${url}`)}`,
  );
};
