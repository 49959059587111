import { types } from "mobx-state-tree";
import makeInspectable from "mobx-devtools-mst";
import { Assistant } from "./assistant";
import { Thread } from "./thread";
import { System } from "./system";
import { Message } from "./message";
import { Batch } from "./batch";
import { File } from "./file";
import { Tool } from "./tool";
import { Knowledge } from "./knowledge";
import { ExtractSession } from "./extractSession";
import { QualityInspection } from "./qualityInspection";
import { DataScreen } from "./dataScreen";
import { Organization } from "./organization";
const RootStore = types.model("RootStore", {
  assistant: Assistant,
  thread: Thread,
  system: System,
  message: Message,
  batch: Batch,
  file: File,
  tool: Tool,
  knowledge: Knowledge,
  extractSession: ExtractSession,
  qualityInspection: QualityInspection,
  dataScreen: DataScreen,
  organization: Organization,
});

export const store = RootStore.create({
  assistant: {
    list: [],
    currentAssistantId: null,
    clone: false,
    edit: false,
    newFile: 0,
  },
  thread: {
    list: [],
    loadSearchData: null,
    loading: false,
    currentThreadMetaData: null,
  },
  system: {
    leftCollapsed: false,
    rightCollapsed: false,
    welcomMessage: "",
    rightContent: "history",
  },
  message: {
    list: [],
    data: {},
    queryIur: [],
    type: "",
  },
  batch: {
    fileList: [],
    taskList: [],
    chatList: [],
    chatItem: [],
    taskState: [0, 0, 0, 0],
    taskSummary: {},
    totalThreads: 0,
    currentChatId: "",
  },
  file: {
    fileList: [],
  },
  tool: {
    toolList: [],
  },
  knowledge: {
    list: [],
    kbdata: [],
    currentKdName: "",
    spinList: false,
    modifier: 0,
    openCheck: false,
    openRM: false,
    search: "",
    openQM: false,
    versionId: "",
    queryProposal: {},
    responseProposal: {},
    multifileData: [],
    taskStatus: {},
    searchParams: {
      shop_name: "",
      item_id: "",
      attr_info: "",
      kb_category: "",
    },
    productList: [],
    searchType: "content",
    fetchStats: 0,
    fetchStatsAll: 0,
    llmknowledge: [],
    productAttrs: [],
    responseAttrs: [],
    attribute: [],
  },
  extractSession: {
    editList: [],
    currentIntentionId: "",
    search: "",
    newIntention: [],
    intentionList: [],
    currenQuerytList: [],
  },
  qualityInspection: {
    fileList: [],
    taskList: [],
    chatList: [],
    chatItem: {},
    chatContent: {
      messages: [],
      threads: [],
    },
    currentTaskId: "",
    chatItemClient: "",
  },
  dataScreen: {
    threadData: [],
    viewThreadId: "",
  },
  organization: {
    list: [],
  },
});

makeInspectable(store);
