import { observer } from "mobx-react-lite";
import { message } from "antd";
import { Input } from "@/components/ui/input";
import { useEffect, useRef, useState } from "react";
import { FileBoxItem } from "./FileBoxItem";
import { BoxHeder } from "../BoxHeder";
import { store } from "@/store";
import { ScrollArea } from "@/components/ui/scroll-area";
import icon_up from "@/assets/icons/icon_up_light.svg";
import icon_downward from "@/assets/icons/icon_downward_light.svg";
import SvgIcon from "@/components/SvgIcon";
// import { FormFileAdd } from "./FormFileAdd";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
export const FileBox = observer(() => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const addFile = () => {
    if (fileRef.current) fileRef.current.click();
  };
  const [messageApi, contextHolder] = message.useMessage();
  const { batch } = store;
  const scrollAreaRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const assistant_id = searchParams.get("id") ?? "";
  const [deleteId, setDeleteId] = useState<number | null>(null);
  useEffect(() => {
    batch.loadFlie(assistant_id);
    if (scrollAreaRef.current) {
      scrollAreaRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      scrollAreaRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const transFromData = (data) => {
    data.map((item) => {
      item.records.map((rec) => {
        rec.msg_id = uuidv4();
      });
      return item;
    });
  };

  const getNewFile = (fileContent, fileName) => {
    const jsonData = JSON.stringify(transFromData(JSON.parse(fileContent)));
    const newfile = new File([jsonData], fileName, {
      type: "application/json",
    });

    return newfile;
  };

  const upLoadFile = (file: File) => {
    setSelectedFile("");
    if (file) {
      const allowedTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/json",
      ];
      if (file && allowedTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            const fileContent: any = event.target.result;
            try {
              // 用于校验JSON是够正确
              const isJson = file.type === "application/json";
              batch
                .uploadFlie(isJson ? getNewFile(fileContent,file.name) : file, assistant_id)
                .then(() => {
                  if (deleteId) {
                    batch.deleteFile(deleteId, assistant_id).then(() => {
                      setDeleteId(null);
                    });
                  }
                });
            } catch (error) {
              message.error("json语法有错误，请修改");
              setSelectedFile("");
            }
          } else {
            setSelectedFile("");
          }
        };
        reader.readAsText(file);
      } else {
        messageApi.open({
          type: "error",
          content: "请上传 .xlsx 或 .json 类型的文件",
        });
      }
    }
  };
  const [showDownward, setShowDownward] = useState(true);
  const [showUp, setShowUp] = useState(false);
  const scrollToDo = (num: number) => {
    if (scrollAreaRef.current) {
      const top =
        scrollAreaRef.current.scrollTop -
        (scrollAreaRef.current.scrollTop % 40) +
        num;
      scrollAreaRef.current.scrollTo({
        top: top,
        behavior: "smooth",
      });
      setTimeout(handleScroll, 260);
    }
  };

  function setShow(scrollHeight: number, scrollTop: number) {
    if (scrollHeight > scrollTop) {
      setShowDownward(true);
    } else {
      setShowDownward(false);
    }
    if (scrollTop > 0) {
      setShowUp(true);
    } else {
      setShowUp(false);
    }
  }

  function handleScroll() {
    if (scrollAreaRef.current) {
      setShow(
        scrollAreaRef.current.scrollHeight - 120,
        scrollAreaRef.current.scrollTop
      );
    }
  }
  const [selectedFile, setSelectedFile] = useState("");
  return (
    <div className="mb-[10px]">
      {contextHolder}
      <BoxHeder title="文件">
        <div className="flex items-center">
          {/* <FormFileAdd /> */}
          <SvgIcon
            name="icon_addFile"
            tip="上传文件"
            onClick={addFile}
            className="w-5 h-5 cursor-pointer"
            color="#ffffff"
          />
          <Input
            ref={fileRef}
            value={selectedFile}
            type="file"
            className="hidden"
            onChange={(e) => {
              if (e.target.files) upLoadFile(e.target.files[0]);
            }}
          />
        </div>
      </BoxHeder>
      <div className="mt-2">
        <div
          className="flex justify-center h-3 cursor-pointer"
          onClick={() => {
            scrollToDo(-120);
          }}
        >
          {batch.fileList.length > 3 && showUp && (
            <img src={icon_up} className="w-3 h-3" alt="icon" />
          )}
        </div>
        <ScrollArea ref={scrollAreaRef} className="h-[120px]">
          {batch.fileList.map((item) => (
            <FileBoxItem
              key={item.id}
              addFile={(id) => {
                setDeleteId(id);
                addFile();
              }}
              fileId={item.id}
              fileName={item.filename}
              location={item.location}
            />
          ))}
        </ScrollArea>
        <div
          className="flex justify-center h-3 cursor-pointer"
          onClick={() => {
            scrollToDo(120);
          }}
        >
          {batch.fileList.length > 3 && showDownward && (
            <img src={icon_downward} className="w-3 h-3" alt="icon" />
          )}
        </div>
      </div>
    </div>
  );
});
