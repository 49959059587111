import { Tooltip } from "@/components";
import { observer } from "mobx-react-lite";
import icon_refresh from "@/assets/icons/icon_refresh_light.svg";
import icon_stop from "@/assets/icons/icon_stop_light.svg";
import icon_play from "@/assets/icons/icon_play_light.svg";
import icon_recover from "@/assets/icons/icon_recover_light.svg";
import icon_succeed from "@/assets/icons/icon_succeed.svg";
import icon_ing from "@/assets/icons/icon_ing_light.svg";
import { store } from "@/store";
import { cn } from "@/lib/utils";
import { ConfirmationDialog } from "@/components/ConfirmationDialog";
import SvgIcon from "@/components/SvgIcon";
import { useLocation } from "react-router-dom";
type Props = {
  fileName: string;
  status?: string;
  taskId: number;
  setPage: (val: number) => void;
};
export const TaskBoxItem = observer(
  ({ fileName, status, taskId, setPage }: Props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const assistant_id = searchParams.get("id") ?? "";
    const clickDelete = () => {
      ConfirmationDialog({ title: "是否删除此任务?" }).then(() => {
        batch.deleteTasks(taskId, assistant_id).then(() => {
          batch.loadTasks(assistant_id);
          batch.setChatItem([]);
          batch.setChatList([]);
        });
      });
    };
    const { batch } = store;
    const stateMap = new Map()
      .set("refresh", icon_refresh)
      .set("stop", icon_stop)
      .set("play", icon_play)
      .set("recover", icon_recover)
      .set("finished", icon_succeed)
      .set("in_progress", icon_ing);
    const tasksOutput = () => {
      batch.setCurrentTask(taskId);
      batch.setCurrentTaskState(status);
      batch.setChatItem([]);
      batch.setChatList([]);
      batch.resetSummary();
      batch
        .tasksOutput(taskId, 1, "", assistant_id)
        .then((res) => {
          const chatList: any = res.data.thread_infos.map((item) =>
            Object.entries(item).flat(1)
          );
          batch.setChatList(chatList);
          setPage(1);
        })
        .catch(() => {
          setPage(1);
          batch.setTaskState([0, 0, 0, 0]);
        });
    };

    const clickDown = () => {
      batch.downloadBatchTasks(taskId, assistant_id).then((res) => {
        const fileContent = res;
        const blob = new Blob([fileContent], { type: "text/plain" });
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", fileName + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileUrl);
      });
      // window.open("/api/v1/batch/tasks/download/32")
    };
    return (
      <div
        className={cn(
          "flex justify-between items-center pl-2 cursor-pointer hover:bg-[#202123]",
          batch.currentTaskId === taskId && "bg-[#202123]"
        )}
      >
        <div className="flex-1 py-2" onClick={() => tasksOutput()}>
          <Tooltip content={fileName}>
            <div className="w-[170px] text-left truncate font-light text-white">
              {fileName}
            </div>
          </Tooltip>
        </div>
        <div className="flex justify-between items-center">
          <SvgIcon
            name="icon_down"
            tip="下载"
            className="w-4 h-4 mr-1 cursor-pointer"
            onClick={() => clickDown()}
            color="#ffffff"
          />
          <img
            // src={stateMap.get(status)}
            src={stateMap.get("play")}
            className="w-4 h-4 mr-1 cursor-default"
            alt="icon"
          />
          <SvgIcon
            tip="删除"
            name="icon_delete"
            className="w-4 h-4 mr-1"
            onClick={() => clickDelete()}
            color="#ffffff"
          />
        </div>
      </div>
    );
  }
);
