import { useEffect, useRef } from "react";
import { ChatMenu } from "./ChatMenu";
import { ChatMessage } from "./ChatMessage";
import { store } from "@/store";
import { GoToAssistants } from "../../components/GoToAssistants";
const BatchChat = () => {
  const chatMessageRef = useRef<any>();
  const { batch } = store;
  function scrollToTop() {
    if (chatMessageRef.current) {
      chatMessageRef.current.scrollToTop(); // 调用子组件暴露给父组件的函数
    }
  }
  useEffect(() => {
    return () => {
      batch.setChatItem([]);
      batch.setChatList([]);
    };
  });
  return (
    <div className="h-screen w-full">
      <GoToAssistants/>
      <div className="flex">
        <ChatMenu scrollToTop={scrollToTop} />
        <ChatMessage ref={chatMessageRef} />
      </div>
    </div>
  );
};

export default BatchChat;
