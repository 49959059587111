import { api } from "@/api";
import { types, flow } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";
const ChatContent = types.model("ChatContent", {
  messages: types.array(types.frozen()),
  threads: types.array(types.frozen()),
});
export const QualityInspection = types
  .model("QualityInspection", {
    fileList: types.array(types.frozen()),
    taskList: types.array(types.frozen()),
    chatList: types.frozen(),
    chatItem: types.frozen(),
    currentTaskId: types.string,
    chatContent: ChatContent,
    chatItemClient: types.string,
    taskItem: types.frozen(),
  })
  .actions((self) => ({
    setFileList(list: any) {
      self.fileList.replace(list);
    },
    setTaskList(list: any) {
      self.taskList.replace(list);
    },
    setChatList(val: any) {
      self.chatList = val;
    },
    setChatItem(val: any) {
      self.chatItem = val;
    },
    setCurrentTaskId(val: string) {
      self.currentTaskId = val;
    },
    setChatContent(val: any) {
      self.chatContent = val;
    },
    setChatItemClient(val) {
      self.chatItemClient = val;
    },
    setTaskItem(val) {
      self.taskItem = val;
    },
  }))
  .actions((self) => {
    const loadFile = flow(function* (assistant_id) {
      const list = yield api.getSeeFile(assistant_id);
      self.setFileList(list.data);
    });
    const deleteFile = flow(function* (id: string, assistant_id) {
      yield api.deleteFile(id);
      loadFile(assistant_id);
    });
    const downFile = flow(function* (file_name: string) {
      const file = yield api.downSeeFile(file_name);
      return file;
    });
    const uploadFile = flow(function* (file, assistant_id) {
      const data = yield api.uploadSeeFile(file, assistant_id);
      return data;
    });
    const updateFile = flow(function* (file, id) {
      const data = yield api.updateSeeFile(file, id);
      return data;
    });
    const createTasks = flow(function* (
      data: {
        file_id;
        task_id?: string;
        assistant_id;
        end_threads;
        begin_threads;
        task_name;
      },
      { config }
    ) {
      yield api.createSeeTask(data, { config });
    });
    const loadTasks = flow(function* (assistant_id) {
      const tasks = yield api.getSeeTask(assistant_id);
      self.setTaskList(tasks.data);
      return tasks;
    });
    const loadChat = flow(function* (task_id) {
      const result = yield api.getSeeChatList(task_id);
      result.data.forEach((item) => {
        item.index = uuidv4();
      });
      self.setChatList(result.data ?? []);
      return result;
    });
    const deleteTasks = flow(function* (task_id, assistant_id) {
      yield api.deleteSeeTask(task_id);
      loadTasks(assistant_id);
    });
    const deleteFileContent = flow(function* (file_id) {
      const data = yield api.deleteSeeFileContent(file_id);
      return data;
    });
    const loadFileContent = flow(function* (task_id, client_name) {
      const res = yield api.getSeeChatList(task_id, client_name);
      self.setChatContent(res.data);
      return res;
    });
    const downResultFile = flow(function* (task_id: string) {
      let fileName = "";
      const file = yield api.downSeeResultFile(task_id).then((res) => {
        fileName =
          res.headers.get("Content-Disposition")?.split("filename=")[1] ?? "";
        return res.blob();
      });
      return { file, fileName };
    });
    const getSesIndex = flow(function* (id: string, version?: number) {
      const res = yield api.getSesIndex(id, version);
      return res;
    });

    const setSesIndexs = flow(function* (data) {
      const res = yield api.setSesIndexs(data);
      return res;
    });
    const updateSesIndexs = flow(function* (data) {
      const res = yield api.updateSesIndexs(data);
      return res;
    });

    return {
      loadFile,
      deleteFile,
      updateFile,
      downFile,
      uploadFile,
      createTasks,
      loadTasks,
      loadChat,
      deleteTasks,
      deleteFileContent,
      loadFileContent,
      downResultFile,
      getSesIndex,
      setSesIndexs,
      updateSesIndexs,
    };
  });
