import { observer } from "mobx-react-lite";
import { Tooltip } from "@/components";
import { TaskBoxItem } from "./TaskBoxItem";
import { BoxHeder } from "../BoxHeder";
import { useEffect, useRef, useState } from "react";
import { store } from "@/store";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Summary } from "./Summary";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import icon_up from "@/assets/icons/icon_up_light.svg";
import icon_downward from "@/assets/icons/icon_downward_light.svg";
import icon_look from "@/assets/icons/icon_look_light.svg";
import { useLocation } from "react-router-dom";
type Props = { setPage: (val: number) => void };
export const TaskBox = observer(({ setPage }: Props) => {
  const { batch } = store;
  const scrollAreaRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const assistant_id = searchParams.get("id") ?? "";
  useEffect(() => {
    batch.loadTasks(assistant_id).then(() => {
      if (batch.taskList.length > 0) {
        batch.setCurrentTask(batch.taskList[0].task_id);
        batch.setCurrentTaskState(batch.taskList[0].status);
        batch
          .tasksOutput(batch.taskList[0].task_id, 1, "", assistant_id)
          .then((res) => {
            const chatList: any = res.data.thread_infos.map((item) =>
              Object.entries(item).flat(1)
            );
            batch.setChatList(chatList);
            setPage(1);
            if (chatList.length > 0) {
              const data = chatList[0];
              batch.getChatItem(data[0]).then((res) => {
                batch.setCurrentChatId(data[0]);
                batch.setChatItem(res.data);
              });
            }
          })
          .catch(() => {
            setPage(1);
            batch.setTaskState([0, 0, 0, 0]);
          });
      }
    });
    if (scrollAreaRef.current) {
      scrollAreaRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      scrollAreaRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [showDownward, setShowDownward] = useState(true);
  const [showUp, setShowUp] = useState(false);
  const scrollToDo = (num: number) => {
    if (scrollAreaRef.current) {
      const top =
        scrollAreaRef.current.scrollTop -
        (scrollAreaRef.current.scrollTop % 40) +
        num;
      scrollAreaRef.current.scrollTo({
        top: top,
        behavior: "smooth",
      });
      setTimeout(handleScroll, 260);
    }
  };

  function setShow(scrollHeight: number, scrollTop: number) {
    if (scrollHeight > scrollTop) {
      setShowDownward(true);
    } else {
      setShowDownward(false);
    }
    if (scrollTop > 0) {
      setShowUp(true);
    } else {
      setShowUp(false);
    }
  }
  function handleScroll() {
    if (scrollAreaRef.current) {
      setShow(
        scrollAreaRef.current.scrollHeight - 120,
        scrollAreaRef.current.scrollTop
      );
    }
  }
  return (
    <div className="mb-[10px]">
      <BoxHeder title="任务">
        <div>
          <Tooltip
            content={`对话${batch.totalThreads ?? 0},消息数
                ${batch.taskSummary.total_count ?? 0}
                ,llm已回复数
                ${batch.taskSummary.llm_data_count ?? 0},召回文档数
                ${batch.taskSummary.full_doc_size_count ?? 0}`}
          >
            <Dialog>
              <DialogTrigger asChild>
                <div className="flex items-center">
                  <img
                    src={icon_look}
                    className="w-[15px] h-[15px] mr-[3px]"
                    alt="icon"
                  />
                  <span className="font-light text-[12px] text-[#aaaaaa]">
                    {batch.totalThreads ?? 0}/
                    {batch.taskSummary.total_count ?? 0}/
                    {batch.taskSummary.llm_data_count ?? 0}/
                    {batch.taskSummary.full_doc_size_count ?? 0}
                  </span>
                </div>
              </DialogTrigger>
              <DialogContent
                className="sm:max-w-[1100px]"
                onInteractOutside={(e) => {
                  e.preventDefault();
                }}
              >
                <DialogHeader>
                  <DialogTitle>任务信息</DialogTitle>
                </DialogHeader>
                <ScrollArea className="h-[calc(_100vh-_200px)]">
                  {batch.taskList.length > 0 && <Summary />}
                </ScrollArea>
              </DialogContent>
            </Dialog>
          </Tooltip>
        </div>
      </BoxHeder>
      <div className="mt-2">
        <div
          className="flex justify-center cursor-pointer h-3"
          onClick={() => {
            scrollToDo(-120);
          }}
        >
          {batch.taskList.length > 3 && showUp && (
            <img src={icon_up} className="w-3 h-3" alt="icon" />
          )}
        </div>
        <ScrollArea ref={scrollAreaRef} className="h-[120px]">
          {batch.taskList.map((item) => (
            <TaskBoxItem
              key={item.task_id}
              taskId={item.task_id}
              status={item.status}
              fileName={
                item.name === "task"
                  ? item.file_name
                  : item.name || item.file_name
              }
              setPage={setPage}
            />
          ))}
        </ScrollArea>
        <div
          className="flex justify-center cursor-pointer h-3"
          onClick={() => {
            scrollToDo(120);
          }}
        >
          {batch.taskList.length > 3 && showDownward && (
            <img src={icon_downward} className="w-3 h-3" alt="icon" />
          )}
        </div>
      </div>
    </div>
  );
});
