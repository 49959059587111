import { LogOut } from "lucide-react";
import icon_usera from "@/assets/icons/icon_usera.svg";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import cookie from "react-cookies";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "@/store";
export function DropdownMenuDemo() {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const { assistant, thread, system, message } = store;
  useEffect(() => {
    setUsername(cookie.load("user")?.username ?? "");
  }, []);
  const logout = () => {
    cookie.remove("user");
    cookie.remove("secret_key");
    assistant.setCurrentAssistantId("");
    localStorage.setItem("path", "/");
    thread.setCurrentThreadId("");
    assistant.setClone(false);
    thread.setList([]);
    system.serRightContent("history");
    system.setRightCollapsed(false);
    message.setList([]);
    localStorage.removeItem("organization");
    navigate("/login");
  };
  return (
    <div className=" absolute bottom-[4px]">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="border-none resize-none outline-offset-0 outline-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
            variant="link"
          >
            <img src={icon_usera} className="w-[20px] h-[20px]" alt="icon" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>{username}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={logout}>
            <LogOut className="mr-2 h-4 w-4" />
            <span>注销</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
