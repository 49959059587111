import React, { useRef, useEffect } from "react";
import { Chart } from "@antv/g2";
import groupBy from "lodash-es/groupBy";

interface Props {
  durations: number[]; // 持续时间的数组
}

export const TaskDurationChart: React.FC<Props> = ({ durations }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // 将数字数组转换为对象数组
    const data = Object.entries(groupBy(durations)).map(([duration, list]) => {
      return {
        size: list.length,
        duration,
      };
    });
    if (chartRef.current) {
      const chart = new Chart({
        container: chartRef.current,
        autoFit: true,
        height: 200,
      });

      chart.interval().data(data).encode("x", "duration").encode("y", "size");

      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [durations]);

  return <div ref={chartRef} />;
};
