import { DataTable } from "@/components/table/data-table";
import { store } from "@/store";
import { observer } from "mobx-react-lite";
import { DataCounts } from "../type";
import { ColumnDef } from "@tanstack/react-table";
import { gotoDebug } from "@/utils/debug";

export const Top5DurationTable = observer(() => {
  const { batch } = store;
  const { taskSummary } = batch;
  const { top_5_llm_data } = taskSummary as DataCounts;
  const columns: ColumnDef<DataCounts["top_5_llm_data"][number]>[] = [
    {
      accessorKey: "thread_id",
      header: "会话ID",
      cell: ({ row }) => {
        const id = row.original.thread_id;
        return (
          <a
            onClick={(e) => {
              e.preventDefault();
              gotoDebug(`thread/${id}`);
            }}
          >
            {id}
          </a>
        );
      },
    },
    {
      accessorKey: "elapsed",
      header: "耗时",
    },
  ];

  return <DataTable columns={columns} data={top_5_llm_data!} />;
});
