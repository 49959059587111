import icon_user from "@/assets/icons/icon_user.svg";
import iocn_jq from "@/assets/icons/iocn_jq.svg";
import icon_copy from "@/assets/icons/icon_copy.svg";
import { ReactNode, useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import icon_dui from "@/assets/icons/icon_dui.svg";
import { cn } from "@/lib/utils";
import icon_tip from "@/assets/icons/icon_tip.svg";
import icon_error from "@/assets/icons/icon_error.svg";
import { store } from "@/store";
import { Tooltip } from "@/components";
import icon_deug from "@/assets/icons/icon_deug.svg";
import { gotoDebug } from "@/utils/debug";
import { timeformat } from "@/utils/timeformat";
import { Space, Tag } from "antd";
import SvgIcon from "@/components/SvgIcon";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ChatLoading } from "@/components/chatloading";
type Props = {
  message: string;
  role: string;
  name: ReactNode;
  llmResult?: string;
  isIcon: boolean;
  status?: string;
  label?: string[];
  extra?: ReactNode;
  id?: string;
  // task_id?: string;
  thumb?: string;
  message_id?: string;
  create_time?: string;
  child?: string[];
  loading?: boolean;
};
export const ChatItem = ({
  message,
  role,
  name,
  isIcon,
  llmResult,
  status,
  extra,
  id,
  // task_id,
  thumb,
  message_id,
  create_time,
  label,
  child = [],
  loading,
}: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [timeHovering, setTimeHovering] = useState(false);
  const [isCopy, setIsCopy] = useState(true);
  const { batch, message: messageStore } = store;
  const onCopy = () => {
    if (!isCopy) return;
    copy(message);
    setIsCopy(false);
    setTimeout(() => {
      setIsCopy(true);
    }, 3000);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
    setTimeHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    setTimeHovering(false);
  };
  const mark = (val: "2" | "-2" | "0") => {
    messageStore
      .updateMessageData({
        thread_id: messageItem?.thread_id ? messageItem?.thread_id : id,
        message_id: messageItem?.id ? messageItem?.id : message_id,
        thumb: val,
      })
      .then(() => {
        batch.getChatItem(batch.chatItem[0].thread_id).then((res) => {
          batch.setChatItem(res.data);
        });
      });
  };
  useEffect(() => {
    if (child.length > 0) {
      getMessagesItem(child[child.length - 1]);
      setShowIndex(child.length - 1);
    }
  }, [child]);

  const [showIndex, setShowIndex] = useState(-1);
  const [messageItem, setMessageItem] = useState<any>({});
  function getMessagesItem(mid: string) {
    messageStore.getMessagesItem(mid).then((m) => {
      const data = {
        id: m.id,
        role: m.role,
        created_at: m.created_at,
        assistant_id: m.assistant_id,
        thread_id: m.thread_id,
        message: m.content[0].text[0].value ?? "",
        metadata: m.metadata,
      };
      setMessageItem(data);
    });
  }
  return (
    <div className="my-[10px] flex-1">
      <div className="flex items-center">
        {isIcon && (
          <img
            src={role === "user" ? icon_user : iocn_jq}
            className="w-5 h-5 mr-1"
            alt="icon"
          />
        )}
        {isIcon && (
          <span className={cn(!isIcon && "pl-6", "font-bold")}>
            {role === "user" ? "您" : name}
          </span>
        )}
        {!isIcon && (
          <Tooltip content={llmResult || "暂无评估信息"}>
            <div className="flex items-center">
              <span className={cn(!isIcon && "pl-6", "font-bold")}>
                {role === "user" ? "您" : name}
              </span>
              <img
                src={status === "yes" ? icon_tip : icon_error}
                className="w-3.5 h-3.5 ml-1"
                alt="icon"
              />
            </div>
          </Tooltip>
        )}
        <span className="text-[12px] text-[#b3b2c0] ml-[10px]">
          {timeHovering && (
            <span className="text-[12px] text-[#b3b2c0] ml-[10px]">
              {messageItem?.created_at
                ? timeformat(messageItem?.created_at)
                : create_time
                ? timeformat(create_time)
                : ""}
            </span>
          )}
        </span>
      </div>
      <div
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className="pl-6 cursor-pointer"
      >
        <div className="whitespace-pre-wrap break-all text-[16px]">
          {loading && isIcon ? (
            <ChatLoading />
          ) : messageItem?.message ? (
            messageItem?.message
          ) : (
            message || "-"
          )}
        </div>
        <div className="h-[42px] mt-[8px]">
          {isHovering && (
            <>
              {label && label.length !== 0 && (
                <div
                  onMouseOver={handleMouseOver}
                  className="text-[#b3b2bf] text-[12px] mb-[5px]"
                >
                  <Space size={[0, 8]} wrap>
                    {label.map((i) => (
                      <Tag key={i}>{i}</Tag>
                    ))}
                  </Space>
                </div>
              )}
              {!loading && (
                <div className="flex items-center">
                  {role !== "user" && child?.length !== 0 && (
                    <span className="mr-[10px]">
                      <LeftOutlined
                        className="w-[10px] h-[10px]"
                        onClick={() => {
                          if (showIndex > -1) {
                            setShowIndex(showIndex - 1);
                            if (showIndex - 1 >= 0) {
                              getMessagesItem(child[showIndex - 1]);
                            } else {
                              setMessageItem({});
                            }
                          }
                        }}
                      />
                      <span className="text-[12px] relative bottom-[2px] select-none">
                        {showIndex + 2} / {child?.length + 1}
                      </span>
                      <RightOutlined
                        className="w-[10px] h-[10px]"
                        onClick={() => {
                          if (showIndex < child?.length - 1) {
                            setShowIndex(showIndex + 1);
                            getMessagesItem(child[showIndex + 1]);
                          }
                        }}
                      />
                    </span>
                  )}
                  <img
                    src={isCopy ? icon_copy : icon_dui}
                    className="w-[15px] h-[15px] mr-[10px] cursor-pointer"
                    onClick={() => onCopy()}
                    alt="icon"
                  />
                  {role !== "user" && (
                    <>
                      <SvgIcon
                        name="icon_praise"
                        className="w-[15px] h-[15px] mr-[10px] cursor-pointer"
                        onClick={() => {
                          if (thumb === "2") {
                            mark("0");
                          } else {
                            mark("2");
                          }
                        }}
                        color={
                          (Object.keys(messageItem).length
                            ? messageItem?.metadata?.thumb
                            : thumb) === "2"
                            ? "#75f654"
                            : "#000000"
                        }
                      />
                      <SvgIcon
                        name="icon_tram"
                        className="w-[15px] h-[15px] mr-[10px] cursor-pointer"
                        onClick={() => {
                          if (thumb === "-2") {
                            mark("0");
                          } else {
                            mark("-2");
                          }
                        }}
                        color={
                          (Object.keys(messageItem).length
                            ? messageItem?.metadata?.thumb
                            : thumb) === "-2"
                            ? "#75f654"
                            : "#000000"
                        }
                      />
                      {extra}
                      <img
                        src={icon_deug}
                        className="w-[13px] h-[13px] mr-[10px] cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          gotoDebug(
                            `thread/${
                              messageItem?.thread_id
                                ? messageItem?.thread_id
                                : id
                            }`
                          );
                        }}
                        alt="icon"
                      ></img>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
