import { DataCounts } from "../type";
import { TaskDurationChart } from "./TaskDurationChat";
import { Top5DurationTable } from "./Top5DurationTable";

export const TaskDuration = ({ taskSummary }: { taskSummary: DataCounts }) => {
  const { elapsed_list } = taskSummary;

  return (
    <div>
      <TaskDurationChart durations={elapsed_list} />
      <Top5DurationTable />
    </div>
  );
};
