import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import * as Sentry from "@sentry/react";
import "@/styles/globals.css";
import packageJson from "../package.json";
import { ConfirmationDialogProvider } from "@/components/ConfirmationDialog";
import "virtual:svg-icons-register";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import zh from "dayjs/locale/zh-cn";
import { Spin } from "antd";
import BatchChat from "./pages/BatchChat";

const lazyLoad = (Comp: React.LazyExoticComponent<any>): React.ReactNode => {
  return (
    <Suspense
      fallback={
        <Spin
          size="large"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      }
    >
      <Comp />
    </Suspense>
  );
};
const router = createHashRouter([
  {
    path: "/login",
    element: lazyLoad(lazy(() => import("./pages/login"))),
  },
  {
    path: "/",
    element: lazyLoad(lazy(() => import("./App"))),
    children: [
      {
        path: "/",
        element: lazyLoad(lazy(() => import("./pages/Root"))),
        children: [
          {
            path: "/",
            element: lazyLoad(lazy(() => import("./pages/AssistantBoard"))),
          },
          {
            path: "/assistants",
            element: lazyLoad(lazy(() => import("./pages/AssistantList"))),
          },
          {
            path: "/knowledge",
            element: lazyLoad(lazy(() => import("./pages/KnowledgeBase"))),
          },
          {
            path: "/knowledge",
            element: lazyLoad(lazy(() => import("./pages/KnowledgeBase"))),
          },
          {
            path: "/setting",
            element: lazyLoad(lazy(() => import("./pages/Setting"))),
          },
        ],
      },
      {
        path: "/chat",
        element: lazyLoad(lazy(() => import("./pages/Chat"))),
      },
      {
        path: "/see",
        element: lazyLoad(lazy(() => import("./pages/QualityInspection"))),
      },
      {
        path: "/batchChat",
        element: <BatchChat />,
      },
      {
        path: "/screen",
        element: lazyLoad(lazy(() => import("./pages/DataScreen"))),
      },
      {
        path: "/mobile_operation",
        element: lazyLoad(lazy(() => import("./pages/MobileOperation"))),
      },
      {
        path: "/thread",
        element: lazyLoad(lazy(() => import("./pages/Chat"))),
      },
    ],
  },
  {
    path: "/sphere_chat",
    element: lazyLoad(lazy(() => import("./pages/LevitatedSphereChat"))),
  },
]);

import.meta.env.PROD &&
  (function () {
    Sentry.init({
      dsn: "http://e105325ff01d39c69d702b9acab2cac1@182.92.156.78:9002/4",
      release: packageJson.version,
      integrations: [
        new Sentry.BrowserTracing({
          // 设置“tracePropagationTargets”以控制应启用哪些 URL 分布式跟踪
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/yourserver\.io\/api/,
          ],
        }),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // 性能监控
      tracesSampleRate: 1.0, //  捕获 100% 的交易会话重播
      replaysSessionSampleRate: 0.1, // 这将采样率设置为 10%。您可能希望在开发过程中将其更改为 100%，然后在生产过程中以较低的速率进行采样
      replaysOnErrorSampleRate: 1.0, // 如果您尚未对整个会话进行采样，请在对发生错误的会话进行采样时将采样率更改为 100%。
    });
  })();
dayjs.locale("zh-cn", zh);
dayjs.extend(calendar);
ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <>
    <RouterProvider router={router} />
    <Toaster />
    <ConfirmationDialogProvider />
    <div id="titBox"></div>
  </>
  // </React.StrictMode>
);
