
export function getUrlParams(url) {
  const params = {};
  const parser = document.createElement('a');
  parser.href = url;
  const queryStr = parser.search.substring(1);
  const vars = queryStr.split('&');
  for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

export function getHashParams(hash) {
  const params = {};
  const vars = hash.slice(hash.indexOf('?') + 1).split('&');
  for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}