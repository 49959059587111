import { api } from "@/api";
import { types, flow } from "mobx-state-tree";

export const Tool = types
  .model("Tool", {
    toolList: types.array(types.frozen()),
  })
  .actions((self) => ({
    setToolList(list) {
      self.toolList = list;
    },
  }))
  .actions((self) => {
    const load = flow(function* () {
      const list = yield api.getTools();
      self.setToolList(list);
    });
    return {
      load,
    };
  });
