import { Tabs, TabsProps } from "antd";
import { TaskSummary } from "./Summary";
import { TaskDuration } from "./TaskDuration";
import { observer } from "mobx-react-lite";
import { store } from "@/store";

export const Summary = observer(() => {
  const { batch } = store;
  const { taskSummary } = batch;
  if (Object.keys(taskSummary).length === 0) return null;
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "概要",
      children: <TaskSummary />,
    },
    {
      key: "2",
      label: "任务耗时",
      children: <TaskDuration taskSummary={taskSummary} />,
    },
  ];
  return <Tabs className="ml-2" items={items} />;
});
