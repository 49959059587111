import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
// 配置 MinIO 服务端点、访问密钥和秘密访问密钥
const minioEndpoint = "http://182.92.156.78:9100"; // 替换为您的 MinIO 实例地址
const accessKeyId = "1DdLIsck5jwmPz3YWOlY";
const secretAccessKey = "icl4n7n9BdGOqcphPIvFgvmpCvzmEmVGvB8k1gr7";

const client = new S3Client({
  endpoint: minioEndpoint,
  credentials: {
    accessKeyId,
    secretAccessKey,
  },
  region: "e.g.muyu",
  forcePathStyle: true, // 对于非 AWS S3 兼容存储（如 MinIO），需要设置为 true
});

async function uploadFile(file, bucketName, objectKey, type) {
  const command = new PutObjectCommand({
    Bucket: bucketName,
    Key: objectKey,
    Body: file,
    ContentType: type,
  });

  try {
    return await client.send(command).then((res) => {
      return { res, url: minioEndpoint + "/rpa/" + objectKey };
    });
  } catch (error) {
    console.error("Error uploading file:", error);
  }
}

export { uploadFile };
