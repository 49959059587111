import { api } from "@/api";
import { types, flow } from "mobx-state-tree";
export const Organization = types
  .model("Organization", {
    list: types.array(types.frozen()),
  })
  .actions((self) => ({
    setList(list) {
      self.list.replace(list);
    },
  }))
  .actions((self) => {
    const load = flow(function* () {
      const res = yield api.getOrganizations();
      self.setList(res.data);
      return res;
    });

    return {
      load,
    };
  });
