import { observer } from "mobx-react-lite";
import { cn } from "@/lib/utils";
import { store } from "@/store";
import { Tooltip } from "@/components";
import { MoreHorizontal } from "lucide-react";
type Props = { name: string; id: number; index: number };
export const ChatBoxItem = observer(({ name, id, index }: Props) => {
  const { batch } = store;
  return (
    <div
      className={cn(
        "flex justify-between items-center p-1 px-2 h-[48px] cursor-pointer hover:bg-[#202123]",
        batch.chatItem[0]?.thread_id === id && "bg-[#202123]"
      )}
    >
      <Tooltip content={name}>
        <div className="w-[230px]  text-left truncate font-light text-white">
          {name}
        </div>
      </Tooltip>
      <div className="w-4">
        {batch.chatItem?.[0]?.thread_id === id && (
          <Tooltip content={`序号:${index + 1}`}>
            <MoreHorizontal className="relative top-[2px] h-4 w-4 text-[#ffffff]" />
          </Tooltip>
        )}
      </div>
    </div>
  );
});
