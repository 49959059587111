import { observer } from "mobx-react-lite";
import logo from "@/assets/imgs/logo.png";
import { Button } from "@/components/ui/button";
import batchChatFile from "./batchChatFile.json";
export const Header = observer(() => {
  return (
    <div className="flex h-[60px] px-4 items-center">
      <div className="w-[28px] rounded-[50%] h-[28px] text-center flex items-center bg-[#ffffff]">
        <img
          src={logo}
          className="w-[24px] inline-block h-[24px] mx-auto"
          alt="logo"
        />
      </div>
      <div className="text-[18px] ml-[8px] text-[#ffffff]">批量对话</div>
      <Button
        size="sm"
        type="button"
        variant="link"
        className="text-[#ffffff] text-[12px]"
        onClick={() => {
          const a = document.createElement("a");
          a.download = "批量对话_模板.xlsx";
          a.style.display = "none";
          a.href =
            "https://muyu-public.oss-cn-beijing.aliyuncs.com/web/myqa-web/templatefile/%E6%89%B9%E9%87%8F%E5%AF%B9%E8%AF%9D_%E6%A8%A1%E6%9D%BF.xlsx";
          document.body.appendChild(a);
          a.click();
          const jsonBlob = new Blob([JSON.stringify(batchChatFile, null, 2)], {
            type: "application/json",
          });
          const url = URL.createObjectURL(jsonBlob);
          a.href = url;
          a.download = "批量对话_模板.json";
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }}
      >
        下载模板
      </Button>
    </div>
  );
});
