import ReactJson from "react-json-view";
import { ScrollArea } from "@/components/ui/scroll-area";
export type PreviewJsonProps = {
  src: object;
  collapsed?: boolean;
};

export const PreviewJson = ({ src, collapsed }: PreviewJsonProps) => {
  return (
    <ScrollArea className="w-full h-full">
      {ReactJson && (
        <ReactJson
          displayObjectSize={false}
          displayDataTypes={false}
          quotesOnKeys={false}
          collapsed={ collapsed ?? true}
          src={src}
          theme={"rjv-default"}
        />
      )}
    </ScrollArea>
  );
};

PreviewJson.displayName = "PreviewJson";

export default PreviewJson;
