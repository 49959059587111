import { observer } from "mobx-react-lite";
import { ChatBoxItem } from "./ChatBoxItem";
import { BoxHeder } from "../BoxHeder";
import { Input } from "@/components/ui/input";
import icon_search from "@/assets/icons/icon_search.svg";
import icon_open from "@/assets/icons/icon_open_light.svg";
import { useState, useEffect, useRef } from "react";
import VirtualList from "rc-virtual-list";
import { List } from "antd";
import { store } from "@/store";
import icon_up from "@/assets/icons/icon_up_light.svg";
import icon_downward from "@/assets/icons/icon_downward_light.svg";
import { useLocation } from "react-router-dom";
type Props = {
  page: number;
  chatBoxOpen: boolean;
  setPage: (val: number) => void;
  scrollToTop: () => void;
  setChatBoxOpen: (val: boolean) => void;
};
export const ChatBox = observer(
  ({ page, chatBoxOpen, setPage, scrollToTop, setChatBoxOpen }: Props) => {
    const { batch } = store;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const assistant_id = searchParams.get("id") ?? "";
    const scrollAreaRef = useRef<any>();
    const onClick = (val) => {
      batch.getChatItem(val[0]).then((res) => {
        batch.setCurrentChatId(val[0]);
        batch.setChatItem(res.data);
        scrollToTop();
      });
    };
    const [loading, setLoading] = useState(false);
    const appendData = (e: any) => {
      if (loading || batch.chatList.length < 20 * page) return;
      setLoading(true);
      const nextPage = page + 1;
      const taskId = batch.currentTaskId ?? -1;
      batch.tasksOutput(taskId, nextPage, filters, assistant_id).then((res) => {
        const chatList: any = res.data.thread_infos?.map((item) =>
          Object.entries(item)?.flat(1)
        );
        batch.setChatList(chatList);
        setLoading(false);
        setPage(nextPage);
        console.log(e);
      });
    };

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
      if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === height) {
        appendData(e);
      }
      setShow(
        e.currentTarget.scrollHeight - getWindowHeight(chatBoxOpen),
        e.currentTarget.scrollTop
      );
    };

    const [height, setHeight] = useState<number>(0);
    function getWindowHeight(open: boolean) {
      return (
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - (open ? 145 : 540)
      );
    }
    useEffect(() => {
      setHeight(getWindowHeight(chatBoxOpen));
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    function handleResize() {
      setHeight(getWindowHeight(chatBoxOpen));
    }
    const [showDownward, setShowDownward] = useState(true);
    const [showUp, setShowUp] = useState(false);
    const scrollToDo = (num: number) => {
      if (scrollAreaRef.current) {
        const top =
          scrollAreaRef.current.getScrollInfo().y -
          (scrollAreaRef.current.getScrollInfo().y % 48) +
          num;
        scrollAreaRef.current.scrollTo(top);
        appendData("");
      }
    };
    function setShow(scrollHeight: number, scrollTop: number) {
      if (scrollHeight > scrollTop) {
        setShowDownward(true);
      } else {
        setShowDownward(false);
      }
      if (scrollTop > 0) {
        setShowUp(true);
      } else {
        setShowUp(false);
      }
    }
    const [filters, setFilters] = useState("");
    const [lock, setLock] = useState(false);
    return (
      <>
        <BoxHeder title="对话">
          <div className="flex items-center">
            <Input
              value={filters}
              className="h-[20px]"
              onInput={(e: any) => {
                setFilters(e.target.value);
              }}
              onKeyUpCapture={(e) => {
                if (e.keyCode === 13) {
                  const nextPage = 1;
                  const taskId = batch.currentTaskId ?? -1;
                  setLock(true);
                  batch
                    .tasksOutput(taskId, nextPage, filters, assistant_id)
                    .then((res) => {
                      batch.setChatList([]);
                      const chatList: any = res.data.thread_infos.map((item) =>
                        Object.entries(item).flat(1)
                      );
                      batch.setChatList(chatList);
                      setLoading(false);
                      setPage(nextPage);
                    })
                    .finally(() => {
                      setTimeout(() => {
                        setLock(false);
                      }, 100);
                    });
                }
              }}
              icon={
                <img
                  src={icon_search}
                  className="w-3 h-3 mr-1 cursor-pointer"
                  alt="icon"
                />
              }
            />
            <img
              src={icon_open}
              alt="icon"
              className="w-[20px] h-[20px] cursor-pointer"
              onClick={() => {
                setHeight(getWindowHeight(!chatBoxOpen));
                setShow(
                  getWindowHeight(chatBoxOpen),
                  batch.chatList.length * 48
                );
                setChatBoxOpen(!chatBoxOpen);
              }}
            />
          </div>
        </BoxHeder>
        <div className="mt-2">
          <div
            className=" flex justify-center cursor-pointer h-[12px]"
            onClick={() => {
              scrollToDo(-height);
            }}
          >
            {batch.chatList.length > 10 && showUp && (
              <img src={icon_up} className="w-[12px] h-[12px]" alt="icon" />
            )}
          </div>
          {batch.chatList.length && !lock && (
            <List>
              <VirtualList
                ref={scrollAreaRef}
                data={batch.chatList}
                height={height}
                itemHeight={47}
                itemKey={(item) => (item ? item[0] : "")}
                virtual={true}
                onScroll={onScroll}
              >
                {(item, index) => {
                  return (
                    <List.Item key={index}>
                      <div onClick={() => onClick(item)}>
                        <ChatBoxItem
                          name={item ? item[1]?.title : ""}
                          id={item ? item[0] : ""}
                          index={index}
                        />
                      </div>
                    </List.Item>
                  );
                }}
              </VirtualList>
            </List>
          )}
          <div
            className="flex justify-center cursor-pointer h-[12px]"
            onClick={() => {
              scrollToDo(height);
            }}
          >
            {batch.chatList.length >= 10 && showDownward && (
              <img
                src={icon_downward}
                className="w-[12px] h-[12px]"
                alt="icon"
              />
            )}
          </div>
        </div>
      </>
    );
  }
);
