"use client";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { observer } from "mobx-react-lite";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { store } from "@/store";
import { useLocation } from "react-router-dom";
// import { Textarea } from "@/components/ui/textarea";
import SvgIcon from "@/components/SvgIcon";
const formSchema = z.object({
  need_llm: z.boolean(),
  llm_config: z.any(),
  task_name: z.string(),
  one_by_one: z.boolean(),
  response: z.boolean(),
  cleanse: z.boolean(),
  meta_config: z.string(),
  process_config: z.any(),
  begin_threads: z.number(),
  end_threads: z.number(),
});

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  fileId: number;
  llmconfig: any[];
  fileName: string;
};

export const FileBoxPublish = observer(
  ({ open, setOpen, fileId, llmconfig, fileName }: Props) => {
    const { batch } = store;
    const [loading, setLoading] = useState(false);
    const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
        need_llm: true,
        llm_config: "",
        begin_threads: 0,
        end_threads: 100,
        one_by_one: true,
        response: false,
        cleanse: false,
        meta_config: '{"pattern": "demo"}',
        process_config: "",
      },
    });
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const assistant_id = searchParams.get("id") ?? "";
    const [llmConfig, setllmConfig] = useState({});
    const [processConfig, setProcessConfig] = useState({});
    const clickPublish = () => {
      setLoading(true);
      batch
        .createTasks({
          file_id: fileId,
          assistant_id,
          llm_config: form.getValues("response") ? llmConfig : null,
          meta_config: form.getValues("cleanse")
            ? JSON.parse(form.getValues("meta_config"))
            : null,
          process_config: form.getValues("cleanse") ? processConfig : null,
          begin_threads: form.getValues("begin_threads"),
          end_threads: form.getValues("end_threads"),
          need_llm: form.getValues("need_llm"),
          task_name: form.getValues("task_name"),
          one_by_one: form.getValues("one_by_one"),
        })
        .then(() => {
          setOpen(false);
          form.reset();
        })
        .finally(() => {
          setLoading(false);
        });
    };
    useEffect(() => {
      form.setValue("task_name", fileName.split(".json")[0]);
    }, [fileName]);
    return (
      <Dialog
        onOpenChange={(val) => {
          if (!val) {
            setOpen(val);
          }
          form.reset();
        }}
        open={open}
      >
        <DialogContent
          className="sm:max-w-[425px]"
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <DialogTitle>发布任务</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form className="space-y-3">
              <FormField
                control={form.control}
                name="task_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>任务名称</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="cleanse"
                render={({ field }) => (
                  <FormItem className="space-y-0 flex items-center">
                    <FormLabel>
                      <div className="flex items-center">
                        <SvgIcon name="icon_tishi" className="mr-[2px]" />
                        <span>数据处理</span>
                      </div>
                    </FormLabel>
                    <FormControl>
                      <Switch
                        className="ml-[10px]"
                        checked={field.value}
                        onCheckedChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {form.watch("cleanse") && (
                <>
                  <FormField
                    control={form.control}
                    name="process_config"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Select
                            onValueChange={(val) => {
                              field.onChange(val);
                              setProcessConfig(llmconfig[Number(val)].config);
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {llmconfig.map((item, index) => (
                                  <SelectItem
                                    key={index}
                                    value={index.toString()}
                                  >
                                    {item.name}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {/* <FormField
                    control={form.control}
                    name="meta_config"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Textarea placeholder="" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  /> */}
                </>
              )}
              <div className="flex items-center pr-[30px] justify-between">
                <FormField
                  control={form.control}
                  name="need_llm"
                  render={({ field }) => (
                    <FormItem className="space-y-0 flex items-center">
                      <FormLabel>
                        <div className="flex items-center">
                          <SvgIcon name="icon_tishi" className="mr-[2px]" />
                          <span>生成回复</span>
                        </div>
                      </FormLabel>
                      <FormControl>
                        <Switch
                          className="ml-[10px]"
                          checked={field.value}
                          onCheckedChange={(value) => {
                            field.onChange(value);
                            if (!value) {
                              form.setValue("one_by_one", true);
                              form.setValue("response", false);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {form.watch("need_llm") && (
                  <>
                    <FormField
                      control={form.control}
                      name="one_by_one"
                      render={({ field }) => (
                        <FormItem className="space-y-0 flex items-center">
                          <FormLabel>逐一提问</FormLabel>
                          <FormControl>
                            <Switch
                              className="ml-[10px]"
                              checked={field.value}
                              onCheckedChange={(value) => {
                                field.onChange(value);
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="response"
                      render={({ field }) => (
                        <FormItem className="space-y-0 flex items-center">
                          <FormLabel>回复评测</FormLabel>
                          <FormControl>
                            <Switch
                              className="ml-[10px]"
                              checked={field.value}
                              onCheckedChange={(value) => {
                                field.onChange(value);
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}
              </div>
              {form.watch("need_llm") && form.watch("response") && (
                <FormField
                  control={form.control}
                  name="llm_config"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Select
                          onValueChange={(val) => {
                            field.onChange(val);
                            setllmConfig(llmconfig[Number(val)].config);
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {llmconfig.map((item, index) => (
                                <SelectItem
                                  key={index}
                                  value={index.toString()}
                                >
                                  {item.name}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              <FormField
                control={form.control}
                name="begin_threads"
                render={() => (
                  <FormItem>
                    <FormLabel>执行会话数</FormLabel>
                    <FormControl>
                      <div className="flex items-center justify-between">
                        <Input
                          type="number"
                          value={form.watch("begin_threads")}
                          onInput={(e: any) => {
                            form.setValue("begin_threads", e.target.value);
                          }}
                        />
                        <span className="mx-[8px]">-</span>
                        <Input
                          type="number"
                          value={form.watch("end_threads")}
                          onInput={(e: any) => {
                            form.setValue("end_threads", e.target.value);
                          }}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="button"
                disabled={loading}
                onClick={() => clickPublish()}
              >
                {loading ? "发布中..." : "发布"}
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    );
  }
);
