import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
type Props = {
  title: string;
  children: ReactNode;
};
export const BoxHeder = observer(({ title, children }: Props) => {
  return (
    <div className="flex justify-between items-center border-b pb-3">
      <span className="text-[12px] text-[#aaaaaa]">{title}</span>
      {children}
    </div>
  );
});
