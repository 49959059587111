import { ChatItem } from "./ChatItem";
import { PopoverJson } from "@/components/json";
import { observer } from "mobx-react-lite";
import SvgIcon from "@/components/SvgIcon";
import { store } from "@/store";
import cloneDeep from "lodash-es/cloneDeep";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { message as messageAntd } from "antd";
import { useEffect, useState } from "react";
type Props = {
  item: any;
  name: string;
  message_ids: string[];
};
export const ChatColumn = observer(({ item, name, message_ids }: Props) => {
  const { message, batch } = store;
  const [loading, setLoading] = useState(false);
  const refresh = () => {
    message
      .messageRetryCreate({
        assistant_id: item.assistant_id,
        thread_id: item.metadata.origin.thread_id,
        message_ids,
      })
      .then((res) => {
        if (res.thread_id) {
          const abortController = new AbortController();
          let reply = "";
          let notError = true;
          setLoading(true);
          const prefixUrl = localStorage.getItem("prefixUrl");
          fetchEventSource(
            `${prefixUrl ? prefixUrl : "/api/v2/"}threads/${
              res.thread_id
            }/runs?stream=true`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "OpenAI-Organization":
                  localStorage.getItem("organization") ?? "null",
              },
              body: JSON.stringify({
                assistant_id: item.assistant_id,
              }),
              openWhenHidden: true,
              signal: abortController.signal,
              onopen(response): any {
                if (
                  response.ok /*&& response.headers.get('content-type') === EventStreamContentType*/
                ) {
                  return; // everything's good
                } else if (
                  response.status >= 400 &&
                  response.status < 500 &&
                  response.status !== 429
                ) {
                  abortController.abort();
                } else {
                  abortController.abort(); // don't retry
                }
              },
              onmessage(msg) {
                if (msg.event === "message") {
                  const j = JSON.parse(msg.data);
                  const c = j["c"];
                  if (c) {
                    reply = reply + c;
                    console.log(reply);
                  }
                } else {
                  notError = false;
                  messageAntd.error("Unkow event: " + msg.data);
                }
              },
              onerror(err) {
                throw err;
              },
              onclose() {
                if (res.thread_id && notError) {
                  message.load(res.thread_id).then((res) => {
                    if (res.length > 0) {
                      message
                        .setMessageChildId({
                          pmsg_id: item.metadata.origin.msg_id,
                          cmsg_id: res.at(-1)?.id ?? "",
                        })
                        .then(() => {
                          batch.getChatItem(batch.currentChatId).then((res) => {
                            batch.setChatItem(res.data);
                            setLoading(false);
                          });
                        });
                    }
                  });
                } else {
                  if (item?.metadata?.origin?.child_id?.length > 1) {
                    getMessagesItem(item?.metadata?.origin?.child_id.pop());
                  } else {
                    setMessageItem({});
                  }
                  setLoading(false);
                }
              },
            }
          );
        }
      });
  };
  const [child, setChild] = useState<string[]>([]);
  const [messageItem, setMessageItem] = useState<any>({});
  const getMessagesItem = (id) => {
    message.getMessagesItem(id).then((m) => {
      const data = {
        id: m.id,
        role: m.role,
        created_at: m.created_at,
        assistant_id: m.assistant_id,
        thread_id: m.thread_id,
        message: m.content[0].text[0].value ?? "",
        metadata: m.metadata,
      };
      setMessageItem(data);
    });
  };
  useEffect(() => {
    if (item?.metadata?.origin?.child_id?.length > 1) {
      const child_id = cloneDeep(item?.metadata?.origin.child_id);
      getMessagesItem(child_id.pop());
      setChild(child_id);
    }
  }, [item]);
  return (
    <div>
      {item?.role === "user" && (
        <ChatItem
          isIcon={true}
          message={item?.content[0]?.text[0]?.value}
          label={item?.metadata?.origin?.labels ?? []}
          name={name ?? ""}
          role="user"
          create_time={item?.metadata?.origin.t ?? item?.created_at}
        />
      )}
      {item?.role === "assistant" && (
        <div className="flex justify-around items-start">
          <ChatItem
            isIcon={true}
            message={messageItem?.message ?? item?.content[0]?.text[0]?.value}
            extra={
              item?.metadata && (
                <>
                  <PopoverJson src={messageItem?.metadata ?? item?.metadata}>
                    <SvgIcon
                      name="icon_debug"
                      size={13}
                      className="mr-[6px] cursor-pointer"
                    />
                  </PopoverJson>
                  <SvgIcon
                    name="icon_refresh"
                    className="w-[13px] h-[13px] mr-[10px] cursor-pointer"
                    onClick={() => refresh()}
                  />
                </>
              )
            }
            name={name}
            loading={loading}
            id={messageItem?.thread_id ?? item?.thread_id}
            role={messageItem?.role ?? item.role}
            // task_id={item.id}
            thumb={messageItem?.metadata?.thumb ?? item.metadata?.thumb ?? ""}
            message_id={messageItem?.id ?? item.id}
            create_time={messageItem?.created_at ?? item.created_at}
          />
          <ChatItem
            isIcon={false}
            status={item?.metadata?.origin?.status ?? ""}
            message={item.metadata?.origin?.content[0]?.text[0]?.value}
            llmResult={item?.metadata?.origin?.llm_result ?? ""}
            name="对比"
            role={item.role}
            loading={loading}
            child={child}
            id={item?.metadata?.origin?.thread_id}
            message_id={item?.metadata?.origin?.msg_id ?? ""}
            thumb={item.metadata?.origin?.thumb ?? ""}
            create_time={item?.metadata?.origin?.created_at}
          />
        </div>
      )}
    </div>
  );
});
