import { observer } from "mobx-react-lite";
import logo from "@/assets/imgs/logo.png";
import { cn } from "@/lib/utils";
type Props = { name: string; description: string; className?: string };
const ChatDefault = observer(({ name, description, className }: Props) => {
  return (
    <div
      className={cn(
        "w-full h-[calc(_100vh-_80px)] justify-center flex items-center",
        className
      )}
    >
      <div className="min-w-[80px] text-center">
        <div className="w-[72px] inline-block rounded-[50%] h-[72px] text-center border leading-[72px] bg-[#ffffff]">
          <img
            src={logo}
            className="w-[48px] inline-block h-[48px] mx-auto"
            alt="logo"
          />
        </div>
        <div className="text-[20px] mt-[15px]">{name}</div>
        <div>{description}</div>
      </div>
    </div>
  );
});
export default ChatDefault;
