import { api } from "@/api";
import { types, flow, SnapshotOut, getRoot } from "mobx-state-tree";
import cloneDeep from "lodash-es/cloneDeep";
const ThreadItem = types.model("ThreadItem", {
  id: types.string,
  created_at: types.number,
  metadata: types.maybe(types.frozen()),
  metadata_: types.maybe(types.frozen()),
  origin: types.maybe(types.frozen()),
  user_content: types.frozen(),
});

export type IThreadItem = SnapshotOut<typeof ThreadItem>;
const ClassDataItem = types.model("ClassDataItem", {
  title: types.string,
  value: types.array(ThreadItem),
});
const ClassData = types.model("ClassData", {
  today: types.maybe(ClassDataItem),
  yesterday: types.maybe(ClassDataItem),
  sevenDaysago: types.maybe(ClassDataItem),
  thirtyDaysAgo: types.maybe(ClassDataItem),
  otherTime: types.maybe(ClassDataItem),
});
export type IClassData = SnapshotOut<typeof ClassData>;
export const Thread = types
  .model("Thread", {
    list: types.array(ThreadItem),
    currentThreadId: types.maybeNull(types.string),
    classData: types.maybeNull(ClassData),
    loadSearchData: types.maybe(types.frozen()),
    loading: types.boolean,
    currentThreadMetaData: types.frozen(),
    reFresh: types.frozen(),
  })
  .actions((self) => ({
    setCurrentThreadId(id: typeof self.currentThreadId) {
      self.currentThreadId = id;
    },
    setClassData(data) {
      self.classData = data;
    },
    setReFresh(data: any) {
      console.log(data)
      self.reFresh = data;
      // self.reFresh = false;
    },
    setList(val) {
      self.list = val;
    },
    setLoadSearchData(val) {
      self.loadSearchData = val;
    },
    setLoading(val) {
      self.loading = val;
    },
    setCurrentThreadMetaData(val) {
      self.currentThreadMetaData = val;
    },
  }))
  .actions((self) => {
    const load = flow(function* (id?: string) {
      const threads: ResolvePromiseReturnType<typeof api.getThreads> =
        yield api.getThreads(1000);
      threads.data.forEach((t: IThreadItem) => {
        if (!t.metadata) {
          t.metadata = {};
        }
      });
      if (id) self.setClassData(sortData(threads.data, id));
      self.setList(threads.data);
      loadMaxGet(threads.data.length === 1000, threads.data.length + 1000, id);
    });
    const loadSearch = flow(function* (data) {
      const threads: ResolvePromiseReturnType<typeof api.getThreadsSearch> =
        yield api.getThreadsSearch(data);
      return threads.data;
      // threads.data.forEach((t: IThreadItem) => {
      //   t.metadata = t?.origin?.metadata_ ?? {};
      // });
      // console.log(threads);
      // if (id) self.setClassData(sortData(threads.data, id));
      // self.setList(threads.data);
      // return threads.data;
    });
    const loadMaxGet = (sh: boolean, pageSize: number, id?: string) => {
      if (sh) {
        api.getThreads(pageSize).then((res: any) => {
          res.data.forEach((t: IThreadItem) => {
            if (!t.metadata) {
              t.metadata = {};
            }
          });
          if (id) self.setClassData(sortData(res.data, id));
          self.setList(cloneDeep(res.data));
          setTimeout(() => {
            loadMaxGet(
              res.data.length === pageSize,
              res.data.length + 1000,
              id
            );
          }, 100);
        });
      }
    };
    const deleteThread = flow(function* (thread_id: string) {
      yield api.deleteThread(thread_id);
      if (self.currentThreadId === thread_id) {
        self.setCurrentThreadId(null);
      }
    });
    const switchThread = (assistant_id: string, thread_id: string) => {
      const root = getRoot<typeof import("./index").store>(self);
      self.setCurrentThreadId(thread_id);
      root.assistant.setCurrentAssistantId(assistant_id);
    };
    const createThread = flow(function* (assistant_id, assistant_name, source, initMetaData?: any) {
      const thread = yield api.createThread(
        assistant_id,
        assistant_name,
        source,
        initMetaData ?? {}
      );
      switchThread(assistant_id, thread.id);
    });

    const updateThread = flow(function* (thread_id, metadata) {
      yield api.updateThread(thread_id, metadata);
    });

    const run = flow(function* (assistant_id: string, thread_id: string) {
      const run = yield api.runThreads({ assistant_id, thread_id });
      return run;
    });
    const getThread = flow(function* (thread_id: string) {
      const thread = yield api.getThread(thread_id);
      return thread;
    });

    return {
      load,
      run,
      loadSearch,
      delete: deleteThread,
      create: createThread,
      switchThread,
      updateThread,
      getThread,
    };
  });

const sortData = (data, id): any => {
  // 分类数组
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  const obj: any = {
    today: {
      title: "今天",
      value: [],
    },
    yesterday: {
      title: "昨天",
      value: [],
    },
    sevenDaysago: {
      title: "7天以内",
      value: [],
    },
    thirtyDaysAgo: {
      title: "30天以内",
      value: [],
    },
    otherTime: {
      title: "其它时间",
      value: [],
    },
  };
  data
    .filter((item) => {
      if (id) return item.metadata.assistant_id === id;
      return false;
    })
    .forEach((item) => {
      // 将日期时间设为 00:00:00 以方便比较
      const currentDate = new Date(item.created_at);
      currentDate.setHours(0, 0, 0, 0);
      // 计算日期差异（以天为单位）
      const timeDiff = Math.floor(
        (todayDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
      );
      if (timeDiff === 0) {
        obj.today.value.push(item);
      } else if (timeDiff === 1) {
        obj.yesterday.value.push(item);
      } else if (timeDiff <= 7 && timeDiff > 1) {
        obj.sevenDaysago.value.push(item);
      } else if (timeDiff <= 30 && timeDiff > 7) {
        obj.thirtyDaysAgo.value.push(item);
      } else {
        obj.otherTime.value.push(item);
      }
    });
  return obj;
};
