import { useMemo, ReactNode } from "react";
import { observer } from "mobx-react-lite";

import { Tooltip } from "antd";
interface SvgIconProps {
  prefix?: string;
  tip?: string | ReactNode;
  name: string;
  color?: string;
  size?: number | string;
  className?: string;
  onClick?: (e) => void;
}

const SvgIcon = observer((props: SvgIconProps) => {
  const {
    prefix = "icon",
    name,
    color = "#000000",
    size = 16,
    className = "",
    tip = "",
    onClick = (e: any) => {
      e.preventDefault();
    },
  } = props;
  const symbolId = useMemo(() => `#${prefix}-${name}`, [prefix, name]);
  return (
    <Tooltip title={tip}>
      <svg
        aria-hidden="true"
        className={className}
        width={size}
        height={size}
        // fill={color}
        onClick={onClick}
        style={{ color }}
      >
        <use href={symbolId} fill={color} />
      </svg>
    </Tooltip>
  );
});
export default SvgIcon;
