import {
  AlertDialog as AlertDialogbox,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  // AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
export function AlertDialog({
  open,
  title,
  description,
  onOK,
  onCancel,
  onOpenChange,
}: {
  open?: boolean;
  title: string;
  description?: string;
  onOK?: () => void;
  onCancel?: () => void;
  onOpenChange?: (open: boolean) => void;
}) {
  const [_open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(open ?? false);
  }, [open]);
  return (
    <AlertDialogbox open={_open} onOpenChange={onOpenChange}>
      {/* <AlertDialogTrigger asChild></AlertDialogTrigger> */}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction
            onClick={() => {
              if (onOK) onOK();
              setOpen(false);
            }}
          >
            确认
          </AlertDialogAction>
          <AlertDialogCancel
            onClick={() => {
              if (onCancel) onCancel();
              setOpen(false);
            }}
          >
            取消
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogbox>
  );
}

export const ConfirmationDialog = ({
  title,
  desc,
}: {
  title: string;
  desc?: string;
}) => {
  const root = createRoot(document.getElementById("confirmation-dialog-root")!); // createRoot(container!) if you use TypeScript
  return new Promise((resolve, reject) => {
    const handleConfirm = () => {
      resolve(true);
      closeDialog();
    };
    const handleCancel = () => {
      reject();
      closeDialog();
    };

    const closeDialog = () => {
      root.unmount();
    };

    root.render(
      <AlertDialog
        open={true}
        onOK={handleConfirm}
        onOpenChange={handleCancel}
        title={title}
        description={desc}
      />
    );
  })
};

export const ConfirmationDialogProvider = () => {
  return <div id="confirmation-dialog-root" />;
};
