import { observer } from "mobx-react-lite";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Header } from "./Header";
import { FileBox } from "./FileBox";
import { TaskBox } from "./TaskBox";
import { ChatBox } from "./ChatBox";
import { useState } from "react";
type Props = { scrollToTop: () => void };
export const ChatMenu = observer(({ scrollToTop }: Props) => {
  const [page, setPage] = useState(1);
  const [chatBoxOpen, setChatBoxOpen] = useState(false);
  return (
    <div className="w-[300px] h-screen bg-black ">
      <Header />
      <ScrollArea className="mt-2 px-4 h-[calc(100vh_-_80px)]">
        {!chatBoxOpen && (
          <>
            <FileBox />
            <TaskBox setPage={(item: number) => setPage(item)} />
          </>
        )}
        <ChatBox
          page={page}
          scrollToTop={scrollToTop}
          setPage={(item: number) => setPage(item)}
          chatBoxOpen={chatBoxOpen}
          setChatBoxOpen={setChatBoxOpen}
        />
      </ScrollArea>
    </div>
  );
});
