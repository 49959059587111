import { ScrollArea } from "@/components/ui/scroll-area";
import { store } from "@/store";
import { useLocation } from "react-router-dom";
import ChatDefault from "@/components/ChatDefault";
import { observer } from "mobx-react-lite";
import { useRef, useImperativeHandle, forwardRef } from "react";
import { ChatColumn } from "./ChatColumn";
import cloneDeep from "lodash-es/cloneDeep";
export const ChatMessage = observer(
  forwardRef((_, ref) => {
    const { batch } = store;
    const scrollAreaRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const name = searchParams.get("name") ?? "";
    const description = searchParams.get("description") ?? "";
    const scrollToTop = () => {
      if (scrollAreaRef.current) {
        scrollAreaRef.current.scrollTop = 0;
      }
    };
    useImperativeHandle(ref, () => {
      return {
        scrollToTop,
      };
    });
    return (
      <div className="relative flex-1">
        <ScrollArea ref={scrollAreaRef} className="h-screen relative">
          {batch.chatItem.length === 0 && (
            <ChatDefault name={name} description={description} />
          )}
          <div className="w-[80%] mx-auto pt-10px">
            {batch.chatItem.map((item: any, index: number) => (
              <ChatColumn
                key={index}
                item={item}
                message_ids={cloneDeep(batch.chatItem)
                  .splice(0, index)
                  .map((item: any) => item?.metadata?.origin?.msg_id ?? "")}
                name={name}
              />
            ))}
          </div>
        </ScrollArea>
      </div>
    );
  })
);
