import { types } from "mobx-state-tree";

export const DataScreen = types
  .model("DataScreen", {
    threadData: types.array(types.frozen()),
    viewThreadId: types.string,
  })
  .actions((self) => ({
    setThreadData(list) {
      self.threadData = list;
    },
    setViewThreadId(id) {
      self.viewThreadId = id;
    }
  }));
