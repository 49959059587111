import type { ReactNode } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import PreviewJson from "./PreviewJson";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
export const PopoverJson = ({
  src,
  children,
  collapsed,
}: {
  src: any;
  children: ReactNode;
  collapsed?: boolean;
}) => {
  // if (typeof window === "undefined") return null;
  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent className="w-[500px] h-96 overflow-scroll	">
        <ScrollArea className="h-[500px]">
          <div className="min-w-[700px]">
            <PreviewJson src={src} collapsed={collapsed} />
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
};

PopoverJson.displayName = "PopoverJson";
