import { types, flow } from "mobx-state-tree";
/**
 * 系统状态相关
 */
const _System = types
  .model("System", {
    leftCollapsed: types.boolean,
    rightCollapsed: types.boolean,
    welcomMessage: types.string,
    rightContent: 'history',
    rightPresets: false,
  })
  .actions((self) => ({
    setLeftCollapsed(open: boolean) {
      self.leftCollapsed = open;
    },
    setRightCollapsed(open: boolean) {
      self.rightCollapsed = open;
    },
    setWelcomMessage(message: string) {
      self.welcomMessage = message;
    },
    serRightContent(type: 'history' | 'log') {
      self.rightContent = type
    },
    setRightPresets(open: boolean) {
      self.rightPresets = open;
    },
  }));

export const System = _System.actions((self) => {
  const loadWelcomeMessage = flow(function* () {
    const data = yield fetch("/static/welcome.md").then((r) => {
      if (r.status === 200) {
        return r.text();
      } else {
        return "";
      }
    });
    self.setWelcomMessage(data);
  });
  return { loadWelcomeMessage };
});
