import { store } from "@/store";
import { observer } from "mobx-react-lite";
import { DataCounts } from "./type";
import { Tooltip } from "@/components";
import SvgIcon from "@/components/SvgIcon";
export const TaskSummary = observer(() => {
  const { batch } = store;
  const { taskSummary, totalThreads } = batch;
  if (Object.keys(taskSummary).length === 0) return null;
  const {
    total_count,
    llm_data_count,
    answer_type_count,
    cls_label_count,
    full_doc_size_count,
    eval_data_count,
    status_yes_count,
    status_no_count,
    status_other_count,
    elapsed_avg,
    reject_thread_count,
    min_elapsed,
    max_elapsed,
    max_eval_data_all,
    min_eval_data_all,
    thumb_up_count,
    thumb_down_count,
  } = taskSummary as DataCounts;

  const renderDict = (
    label: string,
    total: number,
    data: Record<string, number>
  ) => {
    const viewKeyMap = new Map()
      .set("cls", "客户招呼语(cls)")
      .set("rag", "知识学习(rag)")
      .set("reject", "转人工(reject)")
      .set("reason_fail", "推理失败(reason_fail)")
      .set("client-ok", "好的(client-ok)")
      .set("client-thx", "谢谢(client-thx)")
      .set("greeting", "招呼(greeting)");
    return Object.entries(data || {}).map(([key, value]) => (
      <Item
        label={
          <Tooltip
          content={`${label}为${viewKeyMap.get(key) ?? key}的生成回复数`}
          >
            <>
              <SvgIcon
                name="icon_tishi"
                className="mr-[2px] relative top-[2px]"
              />
              {key}的生成回复数：
            </>
          </Tooltip>
        }
        value={`${value} 占比 ${((value / total) * 100).toFixed(2)}%`}
      />
    ));
  };
  const Item = ({ label, value }) => {
    return (
      <div className="flex items-center">
        <span className="flex-1 text-right">{label}</span>
        <span className="flex-1">{value}</span>
      </div>
    );
  };
  const list = [
    <Item label="对话数：" value={totalThreads} />,
    <Item label="消息数：" value={total_count} />,
    <Item
      label="生成回复数："
      value={`${llm_data_count} 占比 ${(
        (llm_data_count / total_count) *
        100
      ).toFixed(2)}%`}
    />,
    <Item
      label="召回文档数："
      value={`${full_doc_size_count} 占比 ${(
        (full_doc_size_count / llm_data_count) *
        100
      ).toFixed(2)}%`}
    />,
    <Item
      label="标注赞踩数："
      value={`赞 ${thumb_up_count ?? 0} 踩 ${thumb_down_count ?? 0}`}
    />,
    <Item
      label="人工用时："
      value={`平均 ${eval_data_count?.toFixed(2) ?? 0}s 最长 ${
        max_eval_data_all?.toFixed(2) ?? 0
      }s 最短 ${min_eval_data_all?.toFixed(2) ?? 0}s`}
    />,
    status_yes_count > 0 && (
      <Item
        label="状态为yes的数据数："
        value={`${status_yes_count} 占比 ${(
          (status_yes_count / eval_data_count) *
          100
        ).toFixed(2)}%`}
      />
    ),
    status_no_count > 0 && (
      <Item
        label="状态为no的数据数："
        value={`${status_no_count} 占比 ${(
          (status_no_count / eval_data_count) *
          100
        ).toFixed(2)}%`}
      />
    ),
    status_other_count > 0 && (
      <Item
        label="状态为other的数据数："
        value={`${status_other_count} 占比 ${(
          (status_other_count / eval_data_count) *
          100
        ).toFixed(2)}%`}
      />
    ),
    <Item
      label="生成用时："
      value={`平均:${elapsed_avg.toFixed(2)}s, 最短:${min_elapsed.toFixed(
        2
      )}s,最长:${max_elapsed.toFixed(2)}s`}
    />,
    <Item
      label="转人工对话数："
      value={`${reject_thread_count} 占比 ${(
        (reject_thread_count / totalThreads) *
        100
      ).toFixed(2)}%`}
    />,
    ...renderDict("类型(answer_type)", llm_data_count, answer_type_count),
    ...renderDict("客户招呼语(cls)", answer_type_count?.cls, cls_label_count),
  ].filter(Boolean);
  return (
    <div className="p-4 bg-white rounded-lg">
      <h2 className="text-lg font-semibold mb-4">统计信息</h2>
      <ul>
        {list.map((item, index) => (
          <li key={index} className="mb-1">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
});
