import { Tooltip } from "@/components";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { store } from "@/store";
import SvgIcon from "@/components/SvgIcon";
import { ConfirmationDialog } from "@/components/ConfirmationDialog";
import { FileBoxPublish } from "./FileBoxPublish";
import { useLocation } from "react-router-dom";
import { message } from "antd";
type ButtonListItem = {
  type: string;
  icon: string;
  tip: string;
};
type Props = {
  fileId: number;
  fileName: string;
  location: string;
  addFile: (id) => void;
};
export const FileBoxItem = observer(
  ({ fileId, addFile, fileName, location }: Props) => {
    const [isHovering, setIsHovering] = useState(false);
    const { batch } = store;
    const [open, setOpen] = useState(false);
    const locations = useLocation();
    const searchParams = new URLSearchParams(locations.search);
    const id = searchParams.get("id") ?? "";
    const buttonList: ButtonListItem[] = [
      {
        type: "publish",
        icon: "icon_publish_light",
        tip: "发布任务",
      },
      {
        type: "down",
        icon: "icon_down",
        tip: "下载",
      },
      {
        type: "recover",
        icon: "icon_recover",
        tip: "替换",
      },
      {
        type: "delete",
        icon: "icon_delete",
        tip: "删除",
      },
    ];
    const [llmconfig, setllmconfig] = useState([]);
    const onClickButton = (type: string) => {
      switch (type) {
        case "publish":
          setOpen(true);
          batch.getllmconfig().then((res) => {
            setllmconfig(res.data);
          });
          break;
        case "down":
          clickDown();
          break;
        case "recover":
          clickRecover();
          break;
        case "insertion":
          messagesCreate();
          break;
        default:
          clickDelete();
          break;
      }
    };
    const clickDown = () => {
      batch.downFile(fileName, id, fileId).then((res) => {
        const fileContent = res;
        const blob = new Blob([fileContent], { type: "text/plain" });
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileUrl);
      });
    };
    const clickRecover = () => {
      addFile(fileId);
    };
    const clickDelete = () => {
      ConfirmationDialog({ title: "是否删除此文件?" })
        .then(() => {
          batch.deleteFile(fileId, id);
        })
        .catch(() => {});
    };
    const handleMouseOver = () => {
      setIsHovering(true);
    };

    const handleMouseOut = () => {
      setIsHovering(false);
    };

    const messagesCreate = () => {
      ConfirmationDialog({ title: "是否将文件内容直接插入到回话列表中?" })
        .then(() => {
          batch
            .messagesCreate({
              assistant_id: id,
              his_file_location: location,
            })
            .then(() => {
              message.success("插入成功");
            });
        })
        .catch(() => {});
    };

    return (
      <div
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className="flex justify-between items-center py-2 pl-2 cursor-pointer hover:bg-[#202123]"
      >
        <Tooltip content={fileName}>
          <div className="font-light w-[170px] text-left truncate text-white">
            {fileName}
          </div>
        </Tooltip>
        {isHovering && (
          <div className="flex justify-between items-center">
            {...buttonList.map((item: ButtonListItem, index) => {
              return (
                <SvgIcon
                  name={item.icon}
                  key={index}
                  tip={item.tip}
                  color="#ffffff"
                  className="w-4 h-4 mr-1 cursor-pointer z-[10]"
                  onClick={() => onClickButton(item.type)}
                />
              );
            })}
          </div>
        )}
        <FileBoxPublish
          open={open}
          setOpen={setOpen}
          fileName={fileName}
          llmconfig={llmconfig}
          fileId={fileId}
        />
      </div>
    );
  }
);
