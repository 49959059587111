import {
  Tooltip as TooltipBox,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipPortal,
} from "@/components/ui/tooltip";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

type Props = {
  children: React.ReactNode;
  content: string | React.ReactNode;
  disabled?: boolean;
  tooltipTriggerProps?: React.FC<TooltipPrimitive.TooltipProviderProps>;
  tooltipContentProps?: React.FC<TooltipPrimitive.TooltipContentProps>;
  tooltipPortalProps?: React.FC<TooltipPrimitive.TooltipPortalProps>;
};
export const Tooltip = (props: Props) => {
  return (
    <TooltipProvider>
      <TooltipBox>
        <TooltipTrigger type="button" {...props.tooltipTriggerProps}>
          {props.children}
        </TooltipTrigger>
        <TooltipPortal>
          {!props.disabled && (
            <TooltipContent {...props.tooltipContentProps}>
              <div className="whitespace-pre-wrap">{props.content}</div>
            </TooltipContent>
          )}
        </TooltipPortal>
      </TooltipBox>
    </TooltipProvider>
  );
};
