import { api } from "@/api";
import { types, flow, SnapshotOut } from "mobx-state-tree";
import dayjs from "dayjs";
const MultifileDataItem = types.model("MultifileDataItem", {
  version_id: types.string,
  file_id: types.string,
  kb_name: types.string,
});
const SearchParams = types.model("SearchParams", {
  shop_name: types.string,
  item_id: types.string,
  attr_info: types.frozen(),
  kb_category: types.string,
});

export type IMultifileDataItem = SnapshotOut<typeof MultifileDataItem>;
export type ISearchParams = SnapshotOut<typeof SearchParams>;

export const Knowledge = types
  .model("Knowledge", {
    list: types.array(types.frozen()),
    kbdata: types.array(types.string),
    currentKdName: types.string,
    spinList: types.boolean,
    modifier: types.number,
    openCheck: types.boolean,
    openRM: types.boolean,
    search: types.string,
    openQM: types.boolean,
    versionId: types.string,
    versionInfo: types.frozen(),
    queryProposal: types.frozen(),
    responseProposal: types.frozen(),
    multifileData: types.array(MultifileDataItem),
    searchParams: SearchParams,
    productList: types.array(types.frozen()),
    searchType: types.string,
    fetchStats: types.number,
    fetchStatsAll: types.number,
    taskStatus: types.frozen(),
    llmknowledge: types.array(types.frozen()),
    productAttrs: types.array(types.frozen()),
    responseAttrs: types.array(types.frozen()),
    attribute: types.array(types.frozen()),
  })
  .actions((self) => ({
    setList(list) {
      self.list = list;
    },
    setKbdata(val) {
      self.kbdata.replace(val);
    },
    setCurrentKdName(val) {
      self.currentKdName = val;
    },
    setSpinList(val) {
      self.spinList = val;
    },
    setModifier(val) {
      self.modifier = val;
    },
    setOpenCheck(val) {
      self.openCheck = val;
    },
    setOpenRM(val) {
      self.openRM = val;
    },
    setOpenQM(val) {
      self.openQM = val;
    },
    setSearch(val) {
      self.search = val;
    },
    setVersionId(val) {
      self.versionId = val;
    },
    setVersionInfo(val) {
      self.versionInfo = val;
    },
    setQueryProposal(val) {
      self.queryProposal = val;
    },
    setResponseProposal(val) {
      self.responseProposal = val;
    },
    setMultifileData(val) {
      self.multifileData = val;
    },
    setSearchParams(val: ISearchParams) {
      self.searchParams = val;
    },
    setProductList(val) {
      self.productList = val;
    },
    setSearchType(val) {
      self.searchType = val;
    },
    setFetchStats(val) {
      self.fetchStats = val;
    },
    setFetchStatsAll(val) {
      self.fetchStatsAll = val;
    },
    setTaskStatus(val) {
      self.taskStatus = val;
    },
    setLlmknowledge(val) {
      self.llmknowledge = val;
    },
    setProductAttrs(val) {
      self.productAttrs = val;
    },
    setResponseAttrs(val) {
      self.responseAttrs = val;
    },
    setAttribute(val) {
      self.attribute = val;
    },
  }))
  .actions((self) => {
    const loadProductQuery = flow(function* (assistant_id) {
      const list = yield api.getProductQuery({
        assistant_id,
      });
      return list;
    });
    const deleteProductQuery = flow(function* (id) {
      yield api.deleteProductQuery(id, self.versionId);
    });
    const addProductQuery = flow(function* (data: {
      product_id;
      cate_id;
      product_qa_id;
      query;
      meta_data?: any;
    }) {
      const res = yield api.addProductQuery({
        ...data,
        version_id: self.versionId,
      });
      return res;
    });
    const updateProductQuery = flow(function* (data: {
      product_id;
      cate_id;
      product_qa_id;
      query;
      id;
      query_id;
      meta_data;
    }) {
      const res = yield api.updateProductQuery({
        ...data,
        version_id: self.versionId,
      });
      return res;
    });
    const loadProductQa = flow(function* ({ page_num, page_size }) {
      const list = yield api.getProductQa({
        page_num,
        page_size,
        version_id: self.versionId,
      });
      return list;
    });
    const deleteProductQa = flow(function* (id) {
      yield api.deleteProductQa(id, self.versionId);
    });
    const addProductQa = flow(function* (
      data: {
        cate_kb_id;
        cate_id;
        product_id;
        answer_rule;
        terms;
        kb_name;
        terms_type;
      },
      versionId?: string
    ) {
      const res = yield api.addProductQa({
        ...data,
        version_id: versionId ?? self.versionId,
      });
      return res;
    });
    const updateProductQa = flow(function* (data: {
      cate_kb_id;
      cate_id;
      product_id;
      answer_rule;
      terms;
      id;
      kb_name;
      intent_id;
    }) {
      yield api.updateProductQa({
        ...data,
        version_id: self.versionId,
      });
    });

    const loadResponse = flow(function* (data: {
      page_num;
      page_size;
      product_qa_id;
    }) {
      const list = yield api.getResponse({
        ...data,
        version_id: self.versionId,
      });
      return list;
    });
    const addResponse = flow(function* (data: {
      response;
      product_qa_id;
      kb_category;
      item_id;
      shop_name;
      attr_info;
    }) {
      return yield api.addResponse({
        ...data,
        version_id: self.versionId,
      });
    });
    const deleteResponse = flow(function* (id) {
      yield api.deleteResponse(id, self.versionId);
    });
    const updateResponse = flow(function* (
      data: {
        response;
        product_qa_id;
        kb_category;
        item_id;
        shop_name;
        attr_info;
        response_id;
        meta_data?: any;
      },
      id
    ) {
      yield api.updateResponse(
        {
          ...data,
          version_id: self.versionId,
        },
        id
      );
    });

    const loadDomain = flow(function* (
      kb_name,
      version_id,
      search?: string,
      terms_type?: string,
      data?: {
        shop: string;
        pid: string;
        attr_info: string;
        kb_category: string;
      }
    ) {
      const list = yield api.getDomain(kb_name, version_id, search, terms_type, data);
      // self.setList(list.data);
      console.log(list)
      return list;
    });

    const loadKbname = flow(function* () {
      const list = yield api.getKbname();
      self.setKbdata(list.data);
      return list;
    });
    const domainUploadFile = flow(function* (file, kb_name, assistant_id) {
      const data = yield api.domainUploadFile(file, kb_name, self.versionId, assistant_id);
      return data;
    });
    const deleteKb = flow(function* (kb_name, versionId) {
      const data = yield api.deleteKb(kb_name, versionId);
      return data;
    });
    const downFile = flow(function* (kb_name) {
      const data = yield api.downDomainFile(kb_name, self.versionId);
      return data;
    });
    const additionalDown = flow(function* (kb_name) {
      const data = yield api.additionalDown(kb_name);
      return data;
    });
    const additionalImportdb = flow(function* (file, kb_name) {
      const data = yield api.additionalImportdb(kb_name, file);
      return data;
    });

    const getDocPath = flow(function* (kb_name, file_name, versionId?: string) {
      const data = yield api.getDocPath(
        kb_name,
        file_name,
        versionId ?? self.versionId
      );
      return data;
    });
    const migrateDocs = flow(function* (data: { file_id; kb_name; override, assistant_id }) {
      return yield api.migrateDocs(data);
    });

    const knowledge = flow(function* (
      data: {
        file_name;
        embeddings;
        metadata;
      },
      version_id?: string
    ) {
      return yield api.knowledge({
        ...data,
        version_id: version_id ?? self.versionId,
      });
    });

    const querySim = flow(function* (id) {
      return yield api.querySim(id, self.versionId);
    });
    const getRecommend = flow(function* (prefix, data, file_id) {
      return yield api.getRecommend(prefix, data, self.versionId, file_id);
    });
    const getResponseRecommend = flow(function* (prefix, data, file_id) {
      return yield api.getResponseRecommend(prefix, data, self.versionId, file_id);
    });
    const deleteSuggest = flow(function* (kb_name, data) {
      const res = yield api.deleteSuggest(kb_name, data, self.versionId);
      loadKbname();
      return res;
    });

    const genHistory = flow(function* (assistant_id, metadata, kb_name) {
      const res = yield api.genHistory(
        assistant_id,
        metadata,
        self.versionId,
        kb_name
      );
      return res;
    });
    // 添加属性
    const addProductAttr = flow(function* (key: string) {
      const res = yield api.addProductAttr(key, self.versionId);
      return res;
    });
    // 删除属性
    const deleteProductAttr = flow(function* (id: string) {
      const res = yield api.deleteProductAttr(id, self.versionId);
      return res;
    });
    // 编辑属性
    const updateProductAttr = flow(function* (
      id: string,
      key: string,
      value: string[]
    ) {
      const res = yield api.updateProductAttr(id, key, self.versionId, value);
      return res;
    });
    const getProductAttr = flow(function* (data: {
      page_size: number;
      page_num: number;
    }) {
      const res = yield api.getProductAttr(
        data.page_num,
        data.page_size,
        self.versionId
      );
      return res;
    });
    // 添加类目
    const addProductCate = flow(function* (key: string, children) {
      const res = yield api.addProductCate(key, self.versionId, children);
      return res;
    });
    // 删除类目
    const deleteProductCate = flow(function* (id: string) {
      const res = yield api.deleteProductCate(id, self.versionId);
      return res;
    });
    // 编辑类目
    const updateProductCate = flow(function* (
      id: string,
      key: string,
      children
    ) {
      const res = yield api.updateProductCate(
        id,
        key,
        self.versionId,
        children
      );
      return res;
    });
    // 获取类目
    const getProductCate = flow(function* (data: {
      page_size: number;
      page_num: number;
    }) {
      const res = yield api.getProductCate(
        data.page_num,
        data.page_size,
        self.versionId
      );
      return res;
    });

    // 添加商品
    const addProduct = flow(function* (data: {
      title: string;
      page_title: string;
      item_id: string;
      price: string;
      category: string;
      properties: string;
      code: string;
      shop_id: string;
      children: any;
      metadata: any;
    }) {
      const res = yield api.addProduct({
        ...data,
        version_id: self.versionId,
      });
      return res;
    });
    // 删除类目
    const deleteProduct = flow(function* (id: string) {
      const res = yield api.deleteProduct(id, self.versionId);
      return res;
    });
    // 编辑商品
    const updateProduct = flow(function* (
      id: string,
      data: {
        title: string;
        page_title: string;
        item_id: string;
        price: string;
        category: string;
        properties: string;
        code: string;
        shop_id: string;
        children: any;
        metadata: any;
      }
    ) {
      const res = yield api.updateProduct(id, {
        ...data,
        version_id: self.versionId,
      });
      return res;
    });
    // 获取商品
    const getProduct = flow(function* (data: {
      page_size: number;
      page_num: number;
    }) {
      const res = yield api.getProduct(
        data.page_num,
        data.page_size,
        self.versionId
      );
      return res;
    });
    // 获取分页商品
    const getProductPage = flow(function* (data: {
      item_id: string;
      title: string;
      attributes: any;
      page_size: number;
      page_num: number;
    }) {
      const res = yield api.getProductPage(
        data.item_id,
        data.title,
        data.attributes,
        data.page_num,
        data.page_size,

      );
      return res;
    });
    // 获取进店商品
    const getProductMasterPage = flow(function* (data: {
      data: string;
      page_size: number;
      page_num: number;
    }) {
      const res = yield api.getProductMasterPage(
        data.data,
        data.page_num,
        data.page_size,
      );
      return res;
    });
    // 获取订单商品
    const getProductSubPage = flow(function* (data: {
      data: string;
      page_size: number;
      page_num: number;
    }) {
      const res = yield api.getProductSubPage(
        data.data,
        data.page_num,
        data.page_size,
      );
      return res;
    });
    // 添加商品
    const addShop = flow(function* (key: string, children: object) {
      const res = yield api.addShop(key, self.versionId, children);
      return res;
    });
    // 删除商品
    const deleteShop = flow(function* (id: string) {
      const res = yield api.deleteShop(id, self.versionId);
      return res;
    });
    // 编辑商品
    const updateShop = flow(function* (
      id: string,
      key: string,
      children?: object
    ) {
      const res = yield api.updateShop(id, key, self.versionId, children);
      return res;
    });
    // 获取商品
    const getShop = flow(function* (data: {
      page_size: number;
      page_num: number;
    }) {
      const res = yield api.getShop(
        data.page_num,
        data.page_size,
        self.versionId
      );
      return res;
    });

    const genResponse = flow(function* (assistant_id, metadata, kb_name) {
      const res = yield api.genResponse(assistant_id, metadata, self.versionId, kb_name);
      return res;
    });

    const getDefaultIntentIds = flow(function* (thread_ids) {
      const res = yield api.getDefaultIntentIds(thread_ids);
      return res;
    });

    const saveIntentIds = flow(function* (data: { thread_id; intent_ids }) {
      const res = yield api.saveIntentIds(data);
      return res;
    });

    const createHistoryQuery = flow(function* (data: {
      version_id;
      kb_name;
      assistant_id;
      metadata;
    }) {
      const res = yield api.createHistoryQuery(data);
      return res;
    });

    const createHistoryResponse = flow(function* (data: {
      version_id;
      kb_name;
      assistant_id;
      metadata;
    }) {
      const res = yield api.createHistoryResponse(data);
      return res;
    });

    const getMultifileList = (
      search?: string,
      data?: any,
    ) => {
      const apiArr = self.multifileData.map((item) => {
        return loadDomain(item.kb_name, item.version_id, search, data);
      });
      return Promise.all(apiArr).then((res) => {
        const list = res
          .map((item) => item.data)
          .flat(1)
          .sort(
            (a, b) =>
              dayjs(b?.updated_at ?? "").valueOf() -
              dayjs(a?.updated_at ?? "").valueOf()
          );
        self.setList(list);
        return list;
      });
    };

    const myoracleTaskStatus = flow(function* (assistant_id: string) {
      const res = yield api.myoracleTaskStatus(assistant_id);
      return res;
    });

    const kmeansIntention = flow(function* (data: {
      version_id: string;
      kb_name: string;
      assistant_id: string;
    }) {
      const res = yield api.kmeansIntention(data);
      return res;
    });

    const myoracleLlmknowledge = flow(function* (task_id: string) {
      const res = yield api.myoracleLlmknowledge(task_id);
      return res;
    });
    const mackLlmknowledge = flow(function* (data: {
      intent_id: string;
      is_done: boolean;
      know_id: string;
      id: string;
    }) {
      const res = yield api.mackLlmknowledge(data);
      return res;
    });

    const platformDiff = flow(function* () {
      const res = yield api.platformDiff();
      return res;
    });

    const platformTask = flow(function* (assistant_id: string) {
      const res = yield api.platformTask(assistant_id);
      return res;
    });

    const platformAuto = flow(function* (assistantId) {
      const res = yield api.platformAuto(assistantId);
      return res;
    });
    const copyProductQuery = flow(function* ({ version_id, intent_id }) {
      const res = yield api.copyProductQuery({ version_id, intent_id });
      return res;
    });


    return {
      loadProductQuery,
      deleteProductQuery,
      addProductQuery,
      updateProductQuery,
      loadProductQa,
      deleteProductQa,
      addProductQa,
      updateProductQa,
      loadResponse,
      addResponse,
      deleteResponse,
      updateResponse,
      loadDomain,
      loadKbname,
      domainUploadFile,
      downFile,
      deleteKb,
      getDocPath,
      migrateDocs,
      knowledge,
      querySim,
      getRecommend,
      deleteSuggest,
      genHistory,
      addProductAttr,
      deleteProductAttr,
      updateProductAttr,
      getProductAttr,
      addProductCate,
      deleteProductCate,
      updateProductCate,
      getProductCate,
      addProduct,
      deleteProduct,
      updateProduct,
      getProduct,
      addShop,
      deleteShop,
      updateShop,
      getShop,
      genResponse,
      getResponseRecommend,
      getDefaultIntentIds,
      saveIntentIds,
      createHistoryQuery,
      createHistoryResponse,
      getMultifileList,
      additionalDown,
      additionalImportdb,
      myoracleTaskStatus,
      kmeansIntention,
      myoracleLlmknowledge,
      mackLlmknowledge,
      platformDiff,
      platformTask,
      platformAuto,
      copyProductQuery,
      getProductPage,
      getProductMasterPage,
      getProductSubPage,
    };
  });
