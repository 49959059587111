import dayjs from "dayjs";
/**
 * 使用 dayjs 库对给定的时间戳或时间字符串进行格式化。
 * @param create_time - 可以是时间戳（number类型）、时间字符串或未定义。 
 * @returns 格式化后的时间字符串，根据时间与当前时间的关系，采用不同的格式。
 */
export function timeformat(create_time: number | string | undefined) {
  // 使用 dayjs 对传入的时间进行处理，并定义不同的时间格式化规则
  return dayjs(create_time).calendar(null, {
    sameDay: "HH:mm:ss", // 当天时间格式：小时:分钟:秒
    nextDay: "[明天] HH:mm:ss", // 明天时间格式：[明天] 小时:分钟:秒
    nextWeek: "dddd HH:mm:ss", // 下周时间格式：星期几 小时:分钟:秒
    lastDay: "[昨天] HH:mm:ss", // 昨天时间格式：[昨天] 小时:分钟:秒
    lastWeek: "YYYY-MM-DD HH:mm:ss", // 上周时间格式：星期几 小时:分钟:秒
    sameElse: "YYYY-MM-DD HH:mm:ss", // 其他时间格式：年-月-日 小时:分
  });
}
